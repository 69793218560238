import React, {useState, Suspense} from 'react'
import {Formiz, FormizStep, useForm} from '@formiz/core';
import {Box, Fade, Grid, Stack} from '@chakra-ui/react';
import {PageLayout} from '../layout/PageLayout';

// Step Buttons
import {NextButton} from './buttons/NextButton';
import {PreviousButton} from './buttons/PreviousButton';
import {DotsStepper} from './utils/DotStepper';

import moment from 'moment';
import {useIntl} from "react-intl";

// Steps
const UserGoals = React.lazy(() => import('./steps/UserGoals'));
const UserDetails = React.lazy(() => import('./steps/UserDetails'));
const Complete = React.lazy(() => import('./steps/Complete'));

const StepperButtons = () => (
    <Stack>
        <Box mt="5">
            <Grid templateColumns="1fr 1fr">
                <Box>
                    <PreviousButton/>
                </Box>
                <Box textAlign="right">
                    <NextButton/>
                </Box>
            </Grid>
        </Box>
    </Stack>
);

export const Stepper = () => {
    const form = useForm({subscribe: 'form'});
    const [formValues, setFormValues] = useState({});

    const [startDate, setStartDate] = useState('');
    const [estimatedEndDate, setEstimatedEndDate] = useState('');
    const [estimatedEndDays, setEstimatedEndDays] = useState(0);

    const [totalDaysCounter, setTotalDaysCounter] = useState('');

    const intl = useIntl();
    const currentUrl = window.location.toString();

    let showComplete = true;

    if (currentUrl.search('/de') !== -1 ||
        currentUrl.search('/uk') !== -1) {
        showComplete = false;
    }

    const handleSubmit = (values) => {
        if (showComplete === false) {
            let outgoingLink = intl.formatMessage({id: "outgoingUrl"});

            if (currentUrl.search('/de_v3') !== -1) {
                outgoingLink = 'https://prima-abnehmen.com/de/calculator-v3.html';
            }
            if (currentUrl.search('/de_v4') !== -1) {
                outgoingLink = 'https://prima-abnehmen.com/de/calculator-v4.html';
            }

            outgoingLink += '?startWeight=' + values.weight + '&targetWeight=' + values.targetWeight;

            if (window.location.search !== '') {
                outgoingLink += '&' + window.location.search.replace('?', '');
            }

            window.location = outgoingLink;
            return;
        }

        setFormValues(values);

        window.scrollTo(0, 0);

        let weight = values.weight;
        let targetWeight = values.targetWeight;
        let bodySize = values.body_size;

        if (window.location.toString().search('/uk') !== -1) {
            weight = parseInt(values.weight, 10) * 6.35;
            targetWeight = parseInt(values.targetWeight, 10) * 6.35;
            bodySize = parseInt(values.body_size, 10) * 30.48;
        }

        const fitnessCalculator = require('fitness-health-calculations');

        let tDee = fitnessCalculator.tdee(
            'male',
            parseInt(values.age),
            parseInt(bodySize),
            parseInt(weight),
            'moderate'
        );

        let estimatedDays = Math.round((weight - targetWeight) * 7000 / tDee);
        let estimatedEndDate = moment().add(estimatedDays, 'days');

        let startDate = moment(estimatedEndDate).subtract(7, 'days');

        setEstimatedEndDays(estimatedDays);
        setStartDate(startDate.format('Do MMMM YYYY'));
        setEstimatedEndDate(estimatedEndDate.format('Do MMMM YYYY'));

        setTotalDaysCounter(estimatedEndDate.diff(moment(), 'days'));
    };

    let currentDate = moment().format('Do MMMM YYYY');

    return (
        <Formiz connect={form} onValidSubmit={handleSubmit}>
            {form.isSubmitted && showComplete === true ?
                <Suspense fallback={<></>}>
                    <Fade in={true} delay={0.1}>
                        <Box>
                            <Complete
                                weight={formValues.weight}
                                targetWeight={formValues.targetWeight}
                                weightDiff={(formValues.weight - formValues.targetWeight)}
                                startDate={startDate}
                                currentDate={currentDate}
                                estimatedEndDate={estimatedEndDate}
                                daysToEstimatedEndDate={estimatedEndDays}
                                totalDaysCounter={totalDaysCounter}
                            />
                        </Box>
                    </Fade>
                </Suspense>
                :
                <PageLayout>
                    <form noValidate onSubmit={form.submitStep}>
                        <Box className="stepDots-container" p="4" borderRadius="md" mb="6">
                            <DotsStepper/>
                        </Box>
                        <FormizStep name="step1">
                            <Suspense fallback={<></>}>
                                <UserDetails/>
                            </Suspense>
                        </FormizStep>

                        <FormizStep name="step2">
                            <Suspense fallback={<></>}>
                                <UserGoals/>
                            </Suspense>
                        </FormizStep>

                        <Stack spacing="6" mt="8">
                            <StepperButtons title="Dots stepper"/>
                        </Stack>
                    </form>
                </PageLayout>
            }
        </Formiz>

    )
}
