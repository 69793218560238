import React, {useRef} from 'react';
import {Box, Badge, Container, Grid, GridItem, Heading, Text} from '@chakra-ui/react';
import {Stream} from "@cloudflare/stream-react";
import logo from '../assets/img/logo.svg';
import {FormattedMessage} from "react-intl";
import reviews from '../assets/img/reviews.jpg';

export const PageHeader = () => {
    let showVideo = false;

    const [hideVideoOverlay, setHideVideoOverlay] = React.useState(false)
    const streamRef = useRef(null);

    const playVideo = () => {
        if (streamRef.current) {
            streamRef.current.play();
            setHideVideoOverlay(true);
        }
    }

    const currentUrl = window.location.toString();

    if (currentUrl.search('/de_v2') !== -1) {
        showVideo = true;
    }

    return (
        // <BackgroundVideo>
        <Box className="header-container" w="100%" py={10}>
            <Container maxW="container.lg">
                <Grid templateColumns="repeat(2, 1fr)">
                    <GridItem className="header-inner-contents" rowSpan={2} colSpan={1}>
                        <Box w="100%" mb={10}>
                            <img className="m4" width="200" src={logo} alt="logo"/>
                        </Box>

                        <Badge fontSize="1em" mb={2}><FormattedMessage id="header.newBadge"/></Badge>
                        <Heading mb={5}><FormattedMessage id="header.headline"/></Heading>
                        <Text fontSize={20}><FormattedMessage id="header.subline"/></Text>

                        <Box mt={5}>
                            <img className="m4" width="100%" src={reviews} alt="reviews"/>
                        </Box>

                        {showVideo === true &&
                            <Box>
                                <div className={'video'}>
                                    {hideVideoOverlay === false &&
                                    <div onClick={playVideo} className={'overlay'}>
                                        <div className={'text'}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45"
                                                 fill="currentColor"
                                                 className="bi bi-play-circle-fill" viewBox="0 0 16 16">
                                                <path
                                                    d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM6.79 5.093A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814l-3.5-2.5z"/>
                                            </svg>

                                            <h3><FormattedMessage id="video.headline"/></h3>
                                            <FormattedMessage id="video.description"/>
                                        </div>
                                    </div>
                                    }

                                    <Stream
                                        poster={'https://prima-abnehmen.com/_Resources/Persistent/f/0/2/c/f02c7a8a95eae41c10d834b9d7cf0aef67110837/vid-thumbnew.jpg'}
                                        streamRef={streamRef}
                                        controls={true}
                                        src={'737f860f21f16475c842b7dd2f435913'}/>
                                </div>
                            </Box>
                        }
                    </GridItem>
                </Grid>
            </Container>
        </Box>
        //</BackgroundVideo>
    )
}
